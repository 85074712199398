import spinner from '../assets/img/loading-spinner.png';

const Loading = ({
  src = spinner,
  text = 'Loading...',
  containerClassName = undefined,
  imgClassName = 'w-7.5 h-7.5',
}: {
  src?: string;
  text?: string;
  containerClassName?: string;
  imgClassName?: string;
}): JSX.Element => {
  return (
    <div
      className={`flex items-center justify-center gap-2 ${
        containerClassName || ''
      }`}
    >
      <img src={src} alt='loading' className={imgClassName} />
      {text && <span>{text}</span>}
    </div>
  );
};

export { Loading };
